<template lang="pug">
.code-insert-platform.mt-3.w-100
  om-body-text(bt400lg) {{ content || $t('codeInsertV2.unas.description') }}
  om-accordion.mt-6
    om-accordion-item(
      :step="1"
      :trackPrefix="getSetupGuideTrackPrefix('step1')"
      :done="isConnected"
    )
      template(#name) {{ $t('codeInsertV2.unas.steps.0.title') }}
      .code-insert-step-indent
        om-body-text(bt400md v-html="$t('codeInsertV2.unas.steps.0.texts.0')")
        insert-code(onlyCode type="unas")
    om-accordion-item(
      :step="2"
      :trackPrefix="getSetupGuideTrackPrefix('step2')"
      :done="isConnected"
    )
      template(#name) {{ $t('codeInsertV2.unas.steps.1.title') }}
      .code-insert-step-indent
        om-body-text(bt400md v-html="$t('codeInsertV2.unas.steps.1.texts.0')")
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/unas/step_2_1.jpg')")
        om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.unas.steps.1.texts.1') }}
        img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/unas/step_2_2.jpg')")
        om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.unas.steps.1.texts.2') }}
      ul.mt-5
        li {{ $t('codeInsertV2.unas.steps.1.texts.3') }}
        li {{ $t('codeInsertV2.unas.steps.1.texts.4') }}
        li {{ $t('codeInsertV2.unas.steps.1.texts.5') }}
        li {{ $t('codeInsertV2.unas.steps.1.texts.6') }}
      img.mt-3(:src="require('@/assets/admin/img/insert-code/platforms/unas/step_2_3.jpg')")
      om-body-text.mt-5(bt400md) {{ $t('codeInsertV2.unas.steps.1.texts.7') }}
      img.mt-3.mb-3(:src="require('@/assets/admin/img/insert-code/platforms/unas/step_2_4.jpg')")
</template>

<script>
  import childRouteMixin from '@/mixins/codeinsert/childRoute';
  import domainStatusMixin from '@/mixins/codeinsert/domainStatus';
  import platformMixin from '@/mixins/codeinsert/platform';

  export default {
    name: 'Unas',
    components: {
      InsertCode: () => import('@/components/CodeInsert/platforms/InsertCode.vue'),
    },
    mixins: [childRouteMixin, domainStatusMixin, platformMixin],
  };
</script>
